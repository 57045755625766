<template>
  <div class="profil">
    <div class="naglowek">
        <div class="left"></div>
        <h1>Notatki</h1>

        <div class="right" @click="go_archive">
            <i v-if="!is_archive" class="fa-solid fa-file-zipper"></i>
            <i v-else class="fa-solid fa-xmark"></i>
            <p>Archiwum</p>
        </div>
    </div>
    <div class="treeselect">
      <Treeselect
        :searchable=false
        :clearable=false
        placeholder="Wybierz komisję"

        :multiple="true"
        :options="options"
        v-model="value"
        :default-expand-level="1"
        >
        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
        </Treeselect>
    </div>

    <div v-if="is_loading">
      <loadingItem medium class="loading" />
    </div>
    <div v-else>

    

          <div v-if="is_archive" class="archive">
              
              <h3>Archiwum:</h3>

              <div v-if="notes_archive_filtered.length > 0">
                    

                    <noteOne v-for="note in notes_archive_filtered" :key="note.id" 
                      :note="note"
                      :more_categories=true
                    />

              </div>
              <div v-else>
                  Brak notatek
              </div>

          </div>

          <div v-else>

                <notesCreate />

                <div v-if="notes_future_filtered.length > 0 || notes_now_filtered.length > 0">


                  <div v-if="notes_now_filtered.length > 0">
                    <h3>W trakcie:</h3>
                  
                    <noteOne v-for="note in notes_now_filtered" :key="note.id" 
                      :note="note"
                      :more_categories="value.length > 1"

                      />
                  
                  </div>

                  <div v-if="notes_future_filtered.length > 0">
                    <h3>Do zrobienia:</h3>

                    <noteOne v-for="note in notes_future_filtered" :key="note.id" 
                      :note="note"
                      :more_categories="value.length > 1"
                      />

                  </div>


                </div>
                <div v-else>
                  Brak notatek
                </div>

          </div>
    
    </div>


  </div>
</template>
<script>

// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@/vue-treeselect.css'

import notesCreate from "@/components/notesCreate.vue";
import noteOne from "@/components/noteOne.vue";
import loadingItem from "@/components/loadingItem.vue";


export default {
  name: "Notatki",
  components: {
    Treeselect,
    notesCreate,
    noteOne,
    loadingItem,
  },
  data() {
    return {

      value: [6,9], //todo get value from backend (my-commision)
      options: this.$store.state.options_notatki,
      is_archive: false,
      is_loading: false,


      backend_data_notes_archive: [
        {
            state: 2,
            priority: 0,
            note: "Moja ze state 2",
            category: [6],
            author: 6,
            day: 14,
            month: 2,
            year: 2022,
        },
        {
            state: 2,
            priority: 1,
            note: "Moja ze state 2",
            category: [9],
            author: 6,
            day: 15,
            month: 2,
            year: 2022,
        },
      ],

      notes_future: [],
      notes_now: [],
      notes_archive: [],

      notes_future_filtered: [],
      notes_now_filtered: [],
      notes_archive_filtered: [],

      backend_data_notes: [
          {
            state: 0,
            priority: 0,
            note: "Moja ze state 0 ale taki naprawde dluzszy",
            category: [6],
            author: 6,
            day: 10,
            month: 2,
            year: 2022,
          },
          {
            state: 0,
            priority: 1,
            note: "Moja 2 ze state 0",
            category: [6],
            author: 6,
            day: 11,
            month: 2,
            year: 2022,
          },
          {
            state: 1,
            priority: 0,
            note: "Moja ze state 1",
            category: [9],
            author: 6,
            day: 13,
            month: 2,
            year: 2022,
          },
          {
            state: 1,
            priority: 2,
            note: "Moja 2 ze state 1",
            category: [6],
            author: 6,
            day: 14,
            month: 2,
            year: 2022,
          },
          
      ],

    };
  },
  
  created() {

    console.log("loggedUserId: "+this.$store.state.loggedUserId)
    this.$store.state.loggedUserId = 5;
    
    this.get_notes_future();
    

    this.get_notes_current();
    

    

  },
  methods: {

      get_notes_future(){
        let notes_now_tmp = this.backend_data_notes.filter(function(note) {
          return note.state == 0;
        })

        this.notes_future = notes_now_tmp.sort(this.$func.compareNotesCurrent)


        if(!this.value.includes(0)){
          this.notes_future_filtered = this.notes_future.filter(note =>{
            return note.category.some(category => this.value.includes(category))
          })
        }
        else
          this.notes_future_filtered = this.notes_future;


      },

      get_notes_current(){
        let notes_now_tmp = this.backend_data_notes.filter(function(note) {
          return note.state == 1;
        })

        this.notes_now = notes_now_tmp.sort(this.$func.compareNotesCurrent)

        if(!this.value.includes(0)){
          this.notes_now_filtered = this.notes_now.filter(note =>{
            return note.category.some(category => this.value.includes(category))
          })
        }
        else{
          this.notes_now_filtered = this.notes_now;
        }


      },
      get_notes_archive(){
        this.notes_archive = this.backend_data_notes_archive.sort(this.$func.compareNotesArchive)
      

      
      },

      go_archive(){

          if(!this.is_archive){
              this.is_loading = true;

              this.get_notes_archive();


              this.notes_archive_filtered = this.notes_archive.filter(note =>{
                return note.category.some(category => this.value.includes(category))
              })

  

              setTimeout(() => {
                this.is_loading = false;
              }, 400)
              //todo tu axios

          }
          
          this.is_archive = !this.is_archive;

      },

  },

  watch:{
    value(){
      console.log("value watch")
      
      if(!this.value.includes(0)){
        this.notes_future_filtered = this.notes_future.filter(note =>{
          return note.category.some(category => this.value.includes(category))
        })

        this.notes_now_filtered = this.notes_now.filter(note =>{
          return note.category.some(category => this.value.includes(category))
        })
      }
      else{
        this.notes_future_filtered = this.notes_future;
        this.notes_now_filtered = this.notes_now;
      }

      if(this.is_archive){
        console.log("and inside archive")

        if(!this.value.includes(0)){
          this.notes_archive_filtered = this.notes_archive.filter(note =>{
              return note.category.some(category => this.value.includes(category))
          })
        }
        else{
          this.notes_archive_filtered = this.notes_archive;
        }

      }
    }
  }

};
</script>
<style lang="scss" scoped>

.treeselect{
  margin-left:20px;
  margin-right:20px;
}

.archive{
  padding-top: 20px;
}

.archive>h3{
  padding-bottom: 10px;
}

.loading{
  margin-top: 60px;
}


</style>