<template>
    <div class="wholeEvent">
      <div class="EventDesc" 
        :class="{EventDesc_current : note.state != 2,
                EventDesc_archive : note.state == 2}">


        <div class="left">

          <div class="checkbox">
              
              <i v-if="note.priority == 1" :class="{priority_1 : note.state != 2}" class="fa-solid fa-angle-up"></i>
              <i v-if="note.priority == 2" :class="{priority_2 : note.state != 2}" class="fa-solid fa-angles-up"></i>
              
          </div>

        </div>
      
        <div class="center" :class="{center_not_clicked : !show}" >

            <p v-if="!is_being_edited" @click="collapse" :class="{note_clicked: this.show}">{{note.note}}</p>
            
            <textarea v-else v-model="title" placeholder="Wprowadź notatkę"></textarea>


      
        </div>

        <div class="right" @click="collapse">

          <div class="checkbox">
              <!--
              <i v-if="this.show" class="fa-solid fa-circle-chevron-up"></i>
              <i v-else class="fa-solid fa-circle-chevron-down"></i>
              -->
              <i :class="{anim_arrow_clicked: this.show}" class="anim_arrow fa-solid fa-caret-down"></i>

          </div>

        </div>

        <div class="collapse" v-if="this.show">

              <!-- Priorytet: {{this.$func.getPriority(note.priority)}} -->
              <div v-if="more_categories">
                Kategoria:

                <div v-if="is_being_edited">
                    <Treeselect                    
                    :searchable=false
                    :clearable=false
                    placeholder="Wybierz kategorię"
                    
                    :multiple="true"
                    :options="options"
                    v-model="value"
                    :default-expand-level="1"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
                    </Treeselect>
                </div>
                <div v-else>
                  <Treeselect
                    :disabled="true"
                    
         
                    
                    :multiple="true"
                    :options="options"
                    v-model="note.category"
                    :default-expand-level="1"

                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.label }}</div>
                    </Treeselect>

                 {{this.$func.getNoteCategory(note.category)}}
                </div>
              </div>

              <div class="desc_date_priority" v-if="!is_being_edited">
                Data utworzenia:
                <div>
                  {{note.day}} {{this.$func.getFullMonthName(note.month)}} {{note.year}}
                </div>
              </div>

              <div class="desc_date_priority" v-if="is_being_edited">
                  Priorytet:
                  <Treeselect                    
                    :searchable=false
                    :clearable=false
                    placeholder="Wybierz priorytet"
                    
                    :options="options_priority"
                    v-model="value_priority"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
                    </Treeselect>
              </div>
              
              <div v-if="!is_author">
                Autor: {{this.$func.getAuthor(note.author)}}
              </div>


              <div v-if="is_loading">
                  <loadingItem small />
              </div>
              <div v-else>

                  <div v-if="to_confirm">

                    <div v-if="!is_author" class="checkbox_confirm">
                      <i v-if="this.is_checked" @click="checkbox_clicked" class="fa-solid fa-circle-check"></i>
                      <i v-else @click="checkbox_clicked" class="fa-regular fa-circle"></i>
                      <span @click="checkbox_clicked"> Wyślij autorowi powiadomienie</span>
                    </div>

                    <div class="flex_container">
              
                      <button @click="cancel_btn">
                        Anuluj
                      </button>

                      <button v-if="!is_being_edited && to_archive" @click="archive_btn_confirm">
                        Potwierdź i archiwizuj
                      </button>
                      <button v-if="!is_being_edited && !to_archive && note.state != 2" @click="go_next_btn_confirm">
                        Potwierdź i przenieś do: "w trakcie"
                      </button>
                      <button v-if="is_being_edited" @click="edit_confirm">
                        Potwierdź edycję
                      </button>
                      <button v-if="note.state == 2" @click="delete_btn_confirm">
                        Potwierdź i usuń
                      </button>
                    </div>
                  </div>

                  <div v-else>
                    <div v-if="note.state != 2" class="flex_container">
                      <button @click="archive_btn">
                        Do archiwum
                      </button>

                      <button @click="edit_btn" v-if="this.is_author">
                        Edytuj
                      </button>

                      <button @click="go_next_btn" v-if="note.state==0">
                        Przenieś do: "w trakcie"
                      </button>
                    </div>
                    <div v-else class="flex_container">
                      <button @click="delete_btn">
                        Usuń
                      </button>
                    </div>
                  </div>

              </div>
        </div>
    
    </div>

  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import loadingItem from "@/components/loadingItem.vue";

export default {
  name: "calendarDayEvents",
  components: {
    loadingItem,
    Treeselect,
  },
  props:{
      note: Object,
      more_categories: Boolean,
  },
  data() {
    return {
      show: false,
      is_author: true, //todo connect with backend, author id
      to_confirm: false,
      to_archive: false,
      is_loading: false,
      is_checked: false,
      is_being_edited: false,
      options: this.$store.state.options_notatki,
      value: this.note.category,
      options_priority: this.$store.state.options_priority,
      value_priority: this.note.priority,
      title: this.note.note,
    };
  },
  
  created() {

  },
  methods: {
    edit_confirm(){

    },
    archive_btn_confirm(){

    },
    go_next_btn_confirm(){

    },
    delete_btn_confirm(){

    },
    delete_btn(){
      this.to_confirm = true;
      this.is_loading = true;
        setTimeout(() => {
          this.is_loading = false;
        }, 400)
    },
    checkbox_clicked(){
      this.is_checked = !this.is_checked;
    },

    cancel_btn(){
      this.to_confirm = false;
      this.to_archive = false;
      this.is_checked = false;
      this.is_being_edited = false;
      this.value = this.note.category;
      this.value_priority = this.note.priority;
      this.title = this.note.note;
    },
    archive_btn(){
      this.to_confirm = true;
      this.to_archive = true;

      this.is_loading = true;
        setTimeout(() => {
          this.is_loading = false;
        }, 400)
    },
    edit_btn(){
      this.is_loading = true;
        setTimeout(() => {
          this.is_loading = false;
          this.is_being_edited = true;
          this.to_confirm = true;
        }, 400)
    },
    go_next_btn(){
      this.to_confirm = true;
      this.to_archive = false;
      this.is_loading = true;
        setTimeout(() => {
          this.is_loading = false;
        }, 400)
    },
    collapse(){
      this.show = !this.show;
      if(!this.show)
        this.cancel_btn();
    },
  },
  watch:{
    }
};
</script>
<style lang="scss" scoped>

.EventDesc_archive{
  background-color: var(--color_background);
  border: 1px solid var(--bottom_gradient);
}
.EventDesc_current{
  background-color: var(--bottom_gradient);
  border: 1px solid var(--top_gradient);
}

.EventDesc{
  //background-color: var(--bottom_gradient);
  border-radius: 5px;
  //border: 1px solid var(--top_gradient);
  margin: 5px 20px 5px;
  _padding: 0px 10px 10px 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  _height: 40px;
}
.wholeEvent{
}

.priority_1{
  color: yellow;
}
.priority_2{
  color: red;
}

.left, .right{
  width: 10%;

  display: flex;
  justify-content: center;
  align-items: top;
  padding-top: 15px;
}

.center{
  width: 80%;
}
.center_not_clicked{
  white-space: nowrap; //----------------------------------------
}
.center>p{
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
}
.note_clicked{
  
}
.date{
  display: inline;
}

.datetime{

}

.bottom_bar{
  width: 100%;
  _height: 20px;
  margin-top: -2px;
  _margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}

.collapse{
    _max-height: 0px;
    _overflow: hidden;
    _transition: max-height 0.2s ease-out;
    width: 100%;
    margin: 10px 20px;
}

.anim_arrow{
  transition-duration: 200ms;
  transition-property: transform;
  color: var(--primary_text);
}
.anim_arrow_clicked{
  transform: rotate(180deg);
}

.flex_container{
  margin-top:10px;
}
button{
  margin: 5px;
}
.checkbox_confirm{
  margin-top: 20px;
}

.desc_date_priority{
  margin-top: 10px;
}
</style>