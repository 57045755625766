var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wholeEvent"},[_c('div',{staticClass:"EventDesc",class:{EventDesc_current : _vm.note.state != 2,
              EventDesc_archive : _vm.note.state == 2}},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"checkbox"},[(_vm.note.priority == 1)?_c('i',{staticClass:"fa-solid fa-angle-up",class:{priority_1 : _vm.note.state != 2}}):_vm._e(),(_vm.note.priority == 2)?_c('i',{staticClass:"fa-solid fa-angles-up",class:{priority_2 : _vm.note.state != 2}}):_vm._e()])]),_c('div',{staticClass:"center",class:{center_not_clicked : !_vm.show}},[(!_vm.is_being_edited)?_c('p',{class:{note_clicked: this.show},on:{"click":_vm.collapse}},[_vm._v(_vm._s(_vm.note.note))]):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.title),expression:"title"}],attrs:{"placeholder":"Wprowadź notatkę"},domProps:{"value":(_vm.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.title=$event.target.value}}})]),_c('div',{staticClass:"right",on:{"click":_vm.collapse}},[_c('div',{staticClass:"checkbox"},[_c('i',{staticClass:"anim_arrow fa-solid fa-caret-down",class:{anim_arrow_clicked: this.show}})])]),(this.show)?_c('div',{staticClass:"collapse"},[(_vm.more_categories)?_c('div',[_vm._v(" Kategoria: "),(_vm.is_being_edited)?_c('div',[_c('Treeselect',{attrs:{"searchable":false,"clearable":false,"placeholder":"Wybierz kategorię","multiple":true,"options":_vm.options,"default-expand-level":1},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
              var node = ref.node;
return _c('div',{},[_vm._v(_vm._s(node.raw.customLabel))])}}],null,false,1505161451),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_c('div',[_c('Treeselect',{attrs:{"disabled":true,"multiple":true,"options":_vm.options,"default-expand-level":1},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
              var node = ref.node;
return _c('div',{},[_vm._v(_vm._s(node.raw.label))])}}],null,false,1992406808),model:{value:(_vm.note.category),callback:function ($$v) {_vm.$set(_vm.note, "category", $$v)},expression:"note.category"}}),_vm._v(" "+_vm._s(this.$func.getNoteCategory(_vm.note.category))+" ")],1)]):_vm._e(),(!_vm.is_being_edited)?_c('div',{staticClass:"desc_date_priority"},[_vm._v(" Data utworzenia: "),_c('div',[_vm._v(" "+_vm._s(_vm.note.day)+" "+_vm._s(this.$func.getFullMonthName(_vm.note.month))+" "+_vm._s(_vm.note.year)+" ")])]):_vm._e(),(_vm.is_being_edited)?_c('div',{staticClass:"desc_date_priority"},[_vm._v(" Priorytet: "),_c('Treeselect',{attrs:{"searchable":false,"clearable":false,"placeholder":"Wybierz priorytet","options":_vm.options_priority},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
              var node = ref.node;
return _c('div',{},[_vm._v(_vm._s(node.raw.customLabel))])}}],null,false,1505161451),model:{value:(_vm.value_priority),callback:function ($$v) {_vm.value_priority=$$v},expression:"value_priority"}})],1):_vm._e(),(!_vm.is_author)?_c('div',[_vm._v(" Autor: "+_vm._s(this.$func.getAuthor(_vm.note.author))+" ")]):_vm._e(),(_vm.is_loading)?_c('div',[_c('loadingItem',{attrs:{"small":""}})],1):_c('div',[(_vm.to_confirm)?_c('div',[(!_vm.is_author)?_c('div',{staticClass:"checkbox_confirm"},[(this.is_checked)?_c('i',{staticClass:"fa-solid fa-circle-check",on:{"click":_vm.checkbox_clicked}}):_c('i',{staticClass:"fa-regular fa-circle",on:{"click":_vm.checkbox_clicked}}),_c('span',{on:{"click":_vm.checkbox_clicked}},[_vm._v(" Wyślij autorowi powiadomienie")])]):_vm._e(),_c('div',{staticClass:"flex_container"},[_c('button',{on:{"click":_vm.cancel_btn}},[_vm._v(" Anuluj ")]),(!_vm.is_being_edited && _vm.to_archive)?_c('button',{on:{"click":_vm.archive_btn_confirm}},[_vm._v(" Potwierdź i archiwizuj ")]):_vm._e(),(!_vm.is_being_edited && !_vm.to_archive && _vm.note.state != 2)?_c('button',{on:{"click":_vm.go_next_btn_confirm}},[_vm._v(" Potwierdź i przenieś do: \"w trakcie\" ")]):_vm._e(),(_vm.is_being_edited)?_c('button',{on:{"click":_vm.edit_confirm}},[_vm._v(" Potwierdź edycję ")]):_vm._e(),(_vm.note.state == 2)?_c('button',{on:{"click":_vm.delete_btn_confirm}},[_vm._v(" Potwierdź i usuń ")]):_vm._e()])]):_c('div',[(_vm.note.state != 2)?_c('div',{staticClass:"flex_container"},[_c('button',{on:{"click":_vm.archive_btn}},[_vm._v(" Do archiwum ")]),(this.is_author)?_c('button',{on:{"click":_vm.edit_btn}},[_vm._v(" Edytuj ")]):_vm._e(),(_vm.note.state==0)?_c('button',{on:{"click":_vm.go_next_btn}},[_vm._v(" Przenieś do: \"w trakcie\" ")]):_vm._e()]):_c('div',{staticClass:"flex_container"},[_c('button',{on:{"click":_vm.delete_btn}},[_vm._v(" Usuń ")])])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }