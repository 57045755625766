<template>
  <div class="creation">


    <div v-if="!this.show" class="create_button" @click="creation_clicked">
          
          <p><i class="fa-1x fa-solid fa-circle-plus"></i> DODAJ NOTATKĘ</p>
    </div>

    <div v-else class="EventDesc" >

        <h3>Tworzenie notatki</h3>

        <div class="center" >

            <textarea v-model="title" placeholder="Wprowadź notatkę"></textarea>

        </div>

        <div class="collapse" >

                Kategoria:

                    <Treeselect                    
                    :searchable=false
                    :clearable=false
                    placeholder="Wybierz kategorię"
                    
                    :multiple="true"
                    :options="options"
                    v-model="value"
                    :default-expand-level="1"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
                    </Treeselect>


              <div v-if="is_being_edited">
                  Priorytet:
                  <Treeselect                    
                    :searchable=false
                    :clearable=false
                    placeholder="Wybierz priorytet"
                    
                    :options="options_priority"
                    v-model="value_priority"
                    >
                        <div slot="value-label" slot-scope="{ node }">{{ node.raw.customLabel }}</div>
                    </Treeselect>
              </div>
              
              

              <div v-if="is_loading">
                  <loadingItem small />
              </div>
              <div v-else>

                  <div v-if="is_error">
                    <p class="error" v-for="error in errors" :key="error.id">{{error}}</p>
                  </div>

                  <div v-if="to_confirm">

                    <div class="flex_container">
              
                      <button @click="cancel_btn">
                        Anuluj
                      </button>

                      <button @click="create_confirm_btn">
                        Potwierdź i utwórz
                      </button>

                    </div>
                  </div>

                  <div v-else class="flex_container">
                    <button @click="cancel_btn">
                        Anuluj
                    </button>
                    <button @click="create_btn">
                      Utwórz
                    </button>

                  </div>

              </div>

      </div>
        

    </div>


  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import loadingItem from "@/components/loadingItem.vue";


export default {
  name: "notesCreate",
  components: {
    Treeselect,
    loadingItem,
  },
  props:{
      note: Object,
  },
  data() {
    return {
      show: false,
      to_confirm: false,
      to_archive: false,
      is_loading: false,
      is_checked: false,
      is_being_edited: true,
      options: this.$store.state.options_notatki,
      value: [],
      value_private: -1,
      options_priority: this.$store.state.options_priority,
      value_priority: 0,
      title: "",
      is_error: false,
      errors: [],
    };
  },
  
  created() {
    this.options =  this.$store.state.options_notatki;
    this.value_private = this.options[0].children.find(opt => opt.customLabel == "Prywatne").id;
  },
  methods: {
    creation_clicked(){
      this.show = !this.show;
    },

    create_confirm_btn(){

    },
    create_btn(){
        this.is_loading = true;
        this.errors = [];
        this.is_error = false;

        if(this.value.length == 0){
          this.is_error = true;
          this.errors.push("Kategoria nie została wybrana")
        } 
        if(this.title == ""){
          this.is_error = true;
          this.errors.push("Notatka jest pusta")
        } 
        if(this.value.includes(this.value_private) && this.value.length > 1){
          this.is_error = true;
          this.errors.push("Notatka nie może być jednocześnie prywatna oraz publiczna")
        }

        if(this.is_error == false){
          this.to_confirm = true;
        }

        setTimeout(() => {
          this.is_loading = false;
        }, 400)
    },
    
    cancel_btn(){
      if(this.to_confirm == false){
          this.show = false;

          this.value = [];
          this.value_priority = 0;
          this.title = "";  
          this.is_error = false;
          this.errors = [];
      } else{
          this.to_confirm = false;
      }
    },

  },
  watch:{
    }
};
</script>
<style lang="scss" scoped>


.EventDesc{
  background-color: var(--bottom_gradient);
  border-radius: 5px;
  border: 1px solid var(--top_gradient);
  margin: 5px 20px 40px;
  padding: 10px 10px 0 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

h3{
  width: 100%;
}
.create_button{
  margin: 20px;
}
.create_button>p{
  margin: 6px;
}


.center{
  width: 100%;
}

.collapse{
  margin: 10px 10px;
}

.flex_container{
  margin-top:10px;
}
button{
  margin: 5px;
}



</style>